
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from 'src/app/home/home.component';
import { LoadingComponent } from 'src/app/shared/components/settings/loading/loading.component';
import { UserLoggedOutGuard } from 'src/app/shared/guard/user-logged-out-guard/user-logged-out.guard';
import { AuthLayoutComponent } from './auth-layout.component';

const routes: Routes = [
  {
    path: '',
    component: AuthLayoutComponent,
    canActivate: [UserLoggedOutGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'enviados',
      },
      {
        path: 'home',
        pathMatch: 'full',
        redirectTo: 'enviados',
      },
      // {
      //   path: 'home',
      //   component: HomeComponent
      // },
      {
        path: 'usuarios',
        loadChildren: () =>
          import('../../modules/users/users.module').then(m => m.UsersModule),
      },
      {
        path: 'enviados',
        loadChildren: () =>
          import('../../modules/send-and-details/send-and-details.module').then(m => m.SendAndDetailsModule),
      },
      {
        path: 'descartados',
        loadChildren: () =>
          import('../../modules/discarded/discarded.module').then(m => m.DiscardedModule),
      },
      // {
      //   path: 'pastas',
      //   loadChildren: () =>
      //     import('../../modules/folder/folder.module').then(m => m.FolderModule),
      // },
      {
        path: 'assinar-em-lote',
        loadChildren: () =>
          import('../../modules/batch-sign/batch-sign.module').then(
            m => m.BatchSignModule,
          ),
      },
      {
        path: 'assinantes',
        loadChildren: () =>
          import('../../modules/subscriber-company/subscriber-company.module').then(
            m => m.SubscriberCompanyModule,
          ),
      },
      {
        path: 'configuracoes',
        loadChildren: () =>
          import('../../modules/settings/settings.module').then(m => m.SettingsModule),
      },
      {
        path: 'assinatura',
        loadChildren: () =>
          import('../../modules/signature/signature.module').then(
            m => m.SignatureModule,
          ),
      },
      {
        path: 'admin',
        loadChildren: () => import('../../modules/admin/admin.module').then(m => m.AdminModule),
      },
      {
        path: 'loading',
        component: LoadingComponent,
      },
      {
        path: 'new-documents',
        loadChildren: () => import('../../modules/new-documents/new-documents.module').then(m => m.NewDocumentsModule),
      }
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthLayoutRoutingModule { }
