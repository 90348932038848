<div class="menu-navegacao-mobile">
  <ul>
    <li>
      <span class="color-bottom" [routerLink]="['enviados']" routerLinkActive="router-button-active">
        <i class="format np-outgoing-mail iconBody26"></i>
        Enviados
      </span>
    </li>
    <li>
      <span class="color-bottom" [routerLink]="['descartados']" routerLinkActive="router-button-active">
        <i class="format np-trash iconBody26"></i>
        Descartados
      </span>
    </li>

    <li>
      <button [routerLink]="['/auth/new-documents']" class="btn btn-secondary-yellow buttom-add-new-document">
        <i style="font-size: 22px;" class="np-add iconBody18"></i>
      </button>
    </li>

    <!-- <li>
      <span class="color-bottom" [routerLink]="['pastas']" routerLinkActive="router-button-active">
        <i class="format np-folder iconBody26"></i>
        Pastas
      </span>
    </li> -->
    <li>
      <span class="color-bottom" [routerLink]="['assinar-em-lote']" routerLinkActive="router-button-active">
        <i class="format np-edit iconBody26"></i>
        Assinar lote
      </span>
    </li>
  </ul>
</div>
