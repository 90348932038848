import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from 'src/app/shared/components/toaster/toaster.service';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { UserService } from 'src/app/shared/services/user/user.service';
import { CpfCnpjValidator } from 'src/app/shared/utils/cnpj-cpf.validator';
import { LoadingService } from '../../../../shared/components/settings/loading/loading.service';
import { ICreateUser, IUser, IUserData } from '../../../../shared/models/user.model';
import { ICreateUserDTO, IUpdateUserDTO } from './../../../../shared/models/user.model';
import { FormValidationService } from './../../../../shared/services/formValidation/form-validation.service';
import { NpaperIcon } from './../../../../shared/types/npaper-icon.type';

interface UserModal {
  title: string;
  icon: NpaperIcon;
  textButton: string;
}

interface IFormValues {
  cpf: string;
  name: string;
  email: string;
  isPrimary: boolean;
  viewAllDocs: boolean;
}

@Component({
  selector: 'app-modal-users',
  templateUrl: './modal-users.component.html',
  styleUrls: ['./modal-users.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModalUsersComponent implements OnInit {

  public formControlCpf: FormControl;
  public formControlNome: FormControl;
  public formControlEmail: FormControl;
  public formControlPrimary: FormControl;
  public formControlViewAllDocs: FormControl;

  private userAuth: IUserData | null;
  public formValues: IFormValues;
  public infoUserModal: UserModal = {
    title: 'Adicionar novo usuário',
    icon: 'np-add-user',
    textButton: 'Salvar',
  };
  public user: IUser | undefined;
  public modeEdit: boolean = false;

  constructor(
    public formValidationService: FormValidationService,
    private userService: UserService,
    private loadingService: LoadingService,
    private toasterService: ToasterService,
    private authService: AuthService,
    private ngbModalActive: NgbActiveModal
  ) {
    this.userAuth = this.authService.getUserLocal();
  }

  ngOnInit(): void {
    this.createForm(this.user);
    if (this.modeEdit) {
      this.modeEditUser();
    }
  }

  private createForm(user?: IUser): void {
    this.formControlCpf = new FormControl(user?.cpf || '', [Validators.required, CpfCnpjValidator.isInvalidCpf]);
    this.formControlEmail = new FormControl(user?.email || '', [Validators.required, Validators.email, Validators.minLength(3), Validators.maxLength(250)]);
    this.formControlPrimary = new FormControl(user?.isPrimary || false, [Validators.required]);
    this.formControlViewAllDocs = new FormControl(user?.viewAllDocs || false, [Validators.required]);
    this.formControlNome = new FormControl(user?.name || '', [Validators.required]);

    if (user !== undefined && user.id === this.userAuth?.id) {
      this.formControlPrimary.disable();
      this.formControlViewAllDocs.disable();
    }

  }

  public submitAddUserForm(): void {
    if (this.modeEdit) {
      this.editUser();
      return;
    }
    this.createUser();
  }

  private async editUser(): Promise<void> {

    if (this.user === undefined || this.user.id === undefined || this.user.customerId === undefined) {
      this.toasterService.showInfo('Usuário não identificado para atualização');
      return;
    }

    this.loadingService.showLoader();

    const user: IUpdateUserDTO = {
      identification: this.formControlCpf.value,
      email: this.formControlEmail.value,
      viewAllDocs: this.formControlViewAllDocs.value,
      isPrimary: this.formControlPrimary.value,
      name: this.formControlNome.value,
      userId: this.user.id,
      customerId: this.user.customerId,
      isBlocked: this.user.isBlocked,
    };

    try {
      const resultUpdateUser = await this.userService.updateUser(user);

      if (resultUpdateUser.success === false) {
        this.toasterService.showError(resultUpdateUser.message || 'Usuário editado com sucesso!');
      }
      this.closed(this.factoryReturnUser(user));
      this.toasterService.showSuccess('Usuário editado com sucesso!');
    } catch (error: any) {
      console.log(error);
      this.toasterService.showError(error.error.message || 'Não foi possível atualizar usuário!');
    }
    this.loadingService.hideLoader();
  }

  private async createUser(): Promise<void> {
    this.loadingService.showLoader();

    const user: ICreateUserDTO = {
      identification: this.formControlCpf.value,
      email: this.formControlEmail.value,
      viewAllDocs: this.formControlPrimary.value,
      isPrimary: this.formControlPrimary.value,
      name: this.formControlNome.value,
    };

    try {
      const resultCreateUser = await this.userService.createUser(user);

      if (resultCreateUser.success === false) {
        this.toasterService.showError(resultCreateUser.message || 'Não foi possível criar usuário.');
      }
      this.closed(this.factoryReturnUser(resultCreateUser.user));
      this.toasterService.showSuccess('Usuário cadastrado com sucesso!');
    } catch (error: any) {
      console.log(error);
      this.toasterService.showError(error.error.message || 'Falha ao criar usuário.');
    }
    this.loadingService.hideLoader();
  }

  public factoryReturnUser(user: ICreateUser | IUpdateUserDTO): IUser {
    return {
      name: user.name,
      cpf: user.identification,
      isBlocked: user.isBlocked,
      isPrimary: user.isPrimary,
      viewAllDocs: user.viewAllDocs,
      email: user.email,
      customerId: user.customerId,
      id: user.userId,
    };
  }

  public closed(user?: IUser): void {
    this.ngbModalActive.close(user);
  }

  private modeEditUser(): void {
    this.infoUserModal = {
      title: 'Editar usuário',
      icon: 'np-edit-2',
      textButton: 'Salvar alterações',
    };
    this.formControlNome.markAsTouched();
    this.formControlEmail.markAsTouched();
    this.formControlCpf.markAsTouched();
  }

  public isFormValid(): boolean {
    let isValid: boolean = true;

    isValid = this.formControlCpf.valid
      && this.formControlEmail.valid
      && this.formControlNome.valid;

    return isValid;
  }

}
