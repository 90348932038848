import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ToasterService } from 'src/app/shared/components/toaster/toaster.service';
import { PaperHttpService } from 'src/app/shared/services/paper/paper.http.service';
import { LoadingService } from '../settings/loading/loading.service';
import { ModalConfirmDescartPaperService } from './modal-confirm-descart-paper.service';

@Component({
  selector: 'app-modal-confirm-descart-paper',
  templateUrl: './modal-confirm-descart-paper.component.html',
  styleUrls: ['./modal-confirm-descart-paper.component.scss']
})
export class ModalConfirmDescartPaperComponent implements OnInit {
  public showConfirm = false;
  public idPaper: number[];
  @Output() update: EventEmitter<void> = new EventEmitter();

  constructor(
    private modalService: ModalConfirmDescartPaperService,
    private paperHttpService: PaperHttpService,
    private loadingService: LoadingService,
    private toasterService: ToasterService,
  ) { }

  ngOnInit(): void {
    this.modalService.getObservable().subscribe(({ idPaper }) => {
      this.showConfirm = true;
      this.idPaper = idPaper;
    });
  }

  public close(): void {
    this.showConfirm = false;
  }

  public async delete(): Promise<void> {
    try {
      this.loadingService.showLoader();
      for (const id of this.idPaper) {
        await this.paperHttpService.deletePaper(id);
      }
      this.toasterService.showSuccess('Documento descartado com sucesso!');
      this.loadingService.hideLoader();
    } catch (error) {
      console.error(error);
    } finally {
      this.close();
      this.update.emit();
    }
  }
}
