import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { ICustomerCreate, ICustomerUpdate } from '../../models/customer';
import { IUpdateNameUserDTO, IUserData } from '../../models/user.model';
import { AuthService } from '../../services/auth/auth.service';
import { UserService } from '../../services/user/user.service';
import { ToasterService } from '../toaster/toaster.service';

@Component({
  selector: 'app-modal-edit-name',
  templateUrl: './modal-edit-name.component.html',
  styleUrls: ['./modal-edit-name.component.scss']
})
export class ModalEditNameComponent implements OnInit {

  confirmResult$: Subject<boolean> = new Subject();

  @Input() public editUserName: ICustomerUpdate;

  public inputFormControl = new FormControl('', [Validators.required]);
  public userAuth: IUserData;
  public formControlInputNome: FormControl;
  public edit: boolean = false;

  constructor(
    private activeModal: NgbActiveModal,
    private toasterService: ToasterService,
    private userService: UserService,
    private authService: AuthService,
  ) { }

  async ngOnInit(): Promise<void> {
    await this.consultaNameUser();
    this.createFormControls();
  }

  onCancel() {
    this.confirmAndClose(false, null);
  }

  private confirmAndClose(confirm: boolean, nome: null | string) {
    this.activeModal.close({ confirm, nome });
  }

  public createFormControls(customer?: ICustomerCreate): void {
    this.formControlInputNome = new FormControl(this.userAuth.name, [Validators.required]);

  }

  public async editNameUser(): Promise<void> {
    const customer: IUpdateNameUserDTO = {
      name: this.formControlInputNome.value,
      identification: this.userAuth.identification,
      email: this.userAuth.email,
      userId: this.userAuth.id,
    };

    try {
      const resultUpdateNameCustomer = await this.userService.updateUser(customer);

      if (resultUpdateNameCustomer.success === false) {
        this.toasterService.showError(resultUpdateNameCustomer.message || 'Não foi possivel editar o pefil');
      }
      this.toasterService.showSuccess('Perfil editado com sucesso!');
      this.confirmAndClose(true, customer.name);
      const userDataLocalStorage = this.authService.getUserLocal();
      if (userDataLocalStorage) {
        userDataLocalStorage.name = customer.name;
        this.authService.setUserLocal(userDataLocalStorage);
      }
    } catch (error: any) {
      console.log(error);
      this.toasterService.showError(error.error.message || 'Não foi possível atualizar usuário!');
    }
  }

  public async consultaNameUser(): Promise<void> {
    try {
      const dadosUsusariosLogado = await this.userService.getDataUser();
      this.userAuth = dadosUsusariosLogado.user;
    } catch (error) {
    }

  }

  public isFormValid(): boolean {
    let isValid: boolean = true;

    isValid = this.formControlInputNome.valid;
    return isValid;
  }

}
