<div
  class="m-2 add-card-folder d-flex align-items-center justify-content-center"
  *ngIf="viewCard == 'createCardFolder'"
  (click)="onCreateNewFolder()"
>
  <i class="np np-folder-plus icon-heading"></i>
  <span class="ml-2 icon-heading">Adicionar nova pasta</span>
</div>

<div
  *ngIf="viewCard == 'cardFolder'"
  class="card-folder m-2 p-3 d-flex flex-column justify-content-between"
>
  <div class="header">
    <div>
      <app-avatar
        *ngIf="sharedUsers.length == 0"
        color="primary"
        icon="np-folder"
        size="small"
        type="square"
      ></app-avatar>
      <app-avatar
        *ngIf="sharedUsers"
        color="primary"
        icon="np-user-folder"
        size="small"
        type="square"
      ></app-avatar>
    </div>
  </div>
  <div>
    <span class="Heading16"> {{ dateCardFolder.name }} </span>
  </div>
  <div class="content d-flex flex-column">
    <span class="wBody12">Compartilhar com</span>
    <div class="mt-2 mb-3">
      <div class="avatars-floating">
        <span
          class="avatar-overlapping"
          *ngFor="let user of sharedUsers; let i = index"
        >
          <app-avatar-one-letter
            [name]="user.name"
            [index]="i"
          ></app-avatar-one-letter>
        </span>
        <span class="btn-overlapping">
          <button
            class="btn-circle d-flex align-items-center justify-content-center ml-2 iconBody14"
            ngbTooltip="Compartilhar pasta"
            tooltipClass="btn-tooltip"
            (click)="onShareFolder()"
          >
            <i class="np np-add-user icon-content"></i>
          </button>
        </span>
      </div>
    </div>

    <span class="wBody12 w-100">
      <i class="np np-file"></i>
      <span
        *ngIf="dateCardFolder.document; else noneSharedDocuments"
        class="ml-1"
        >{{ dateCardFolder.document }} documentos</span
      >
      <ng-template #noneSharedDocuments> Não possui documentos </ng-template>
    </span>
    <!-- <span class="wBody12 w-100 my-2">
      <i class="np np-clock"> </i>
      Criada em
      {{ dateCardFolder.date | date: 'dd MMM yyyy - HH:mm' | titlecase }}
    </span> -->
  </div>
  <div class="footer row">
    <div class="col-6">
      <button
        class="btn-icon mr-2"
        (click)="onEditNameFolder()"
        ngbTooltip="Editar"
        tooltipClass="btn-tooltip"
      >
        <i class="np-edit-2"> </i>
      </button>
      <button
        class="btn-icon"
        (click)="onDelete()"
        ngbTooltip="Excluir"
        tooltipClass="btn-tooltip"
      >
        <i class="np-trash"></i>
      </button>
    </div>
    <!-- <div class="col-6 d-flex justify-content-end">
      <button
        class="btn-icon mr-2"
        (click)="onMoveDocument()"
        ngbTooltip="Mover documentos"
        tooltipClass="btn-tooltip"
      >
        <i class="np-move-folder"> </i>
      </button>
      <button
        class="btn-icon"
        ngbTooltip="Abrir pasta"
        tooltipClass="btn-tooltip"
      >
        <i class="np-angle-right"></i>
      </button>
    </div> -->
  </div>
</div>
