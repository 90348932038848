<div class="modal-user">
  <div class="content">
    <div class="px-3 pb-3 pt-1 p-md-4">
      <div class="header d-flex justify-content-between">
        <div class="d-flex align-items-center">
          <app-avatar class="d-none d-md-inline mr-3" color="primary" [icon]="infoUserModal.icon" size="small"></app-avatar>
          <span class="Heading20">{{ infoUserModal.title }}</span>
        </div>
        <i class="iconBody22 np np-close btn-icon-close" (click)="closed()"></i>
      </div>
      <main class="content-user-add mt-0 mt-md-3">
        <div class="row">
          <div class="col-md-6">
            <app-input [label]="'CPF'" [inputControl]="formControlCpf" [isRequired]="true"
              placeholder="Insira um número de CPF válido" [mask]="'000.000.000-00'" labelErro="um cpf"></app-input>
          </div>
          <div class="col-md-6">
            <app-input [label]="'Nome'" [inputControl]="formControlNome" [isRequired]="true"
              placeholder="Insira um nome" labelErro="um nome">
            </app-input>
          </div>
          <div class="col-md-6">
            <app-input [label]="'E-mail'" [inputControl]="formControlEmail" [isRequired]="true"
              placeholder="Insira um endereço de e-mail" labelErro="um e-mail">
            </app-input>
          </div>
        </div>
        <div class="card mt-4">
          <div class="row">
            <div class="col-12 text-left">
              <div class="border-bottom border-color-secondary body14 p-2">
                <strong>Qual o tipo de permissão do usuário?</strong>
              </div>
            </div>
            <div class="row col-12 d-flex align-items-md-center wBody14 pl-4 pt-2 pr-1">
              <div class="col-3 col-md-1 col-lg-1 mr-lg-0 mr-md-1 d-flex justify-content-end justify-content-md-start pr-0">
                <app-switch [form]="formControlPrimary" class="ml-0 ml-md-2"></app-switch>
              </div>
              <div class="col-9 col-md-10 col-lg-11 color-gray-500 p-1 px-md-3">
                Pode modificar qualquer usuário da conta
              </div>
            </div>
            <div class="row col-12 d-flex align-items-md-center wBody14 pl-4 pb-2 pr-1 mt-1 mt-md-0">
              <div class="col-3 col-md-1 col-lg-1 mr-lg-0 mr-md-1 d-flex justify-content-end justify-content-md-start pr-0">
                <app-switch [form]="formControlViewAllDocs" class="ml-0 ml-md-2"></app-switch>
              </div>
              <div class="col-9 col-md-10 col-lg-11 color-gray-500 p-1 px-md-3">
                Pode visualizar documentos enviados de qualquer usuário da conta
              </div>
            </div>
          </div>
        </div>
        <footer class="d-flex justify-content-end mt-4">
          <button ngbAutofocus class="btn btn-secondary body14 mr-2" (click)="closed()">
            <i class="np-error mr-2"></i>
            Fechar
          </button>
          <button (click)="submitAddUserForm()" class="body14 btn btn-primary" [disabled]="!isFormValid()">
            <i class="np-done mr-2"></i>
            {{ infoUserModal.textButton }}
          </button>
        </footer>
      </main>
    </div>
  </div>
</div>