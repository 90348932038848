<div class="send-papers">
  <header class="header p-3">
    <div class="d-flex justify-content-between align-items-center">
      <div
        class="Heading20 col-6"
        [ngClass]="{
          Heading16: isVisaoMobile && !openInputSearchAssunto,
          'd-none': isVisaoMobile && openInputSearchAssunto
        }"
      >
        Documentos enviados
      </div>

      <div class="d-flex search">
        <div
          class="select-search-assunto"
          [ngClass]="{
            'open-select-search-assunto': openInputSearchAssunto,
            'padding-select-search': !isPaddingSelect
          }"
        >
          <app-select-search
            (eventFocus)="onFocusInputSearchAssunto($event)"
            (eventSelectOption)="onAssuntoSelect($event)"
            [optionsAssunto]="optionAssuntoSearch"
            [formSelect]="formControlSelectFilterInAssunto"
            [isSearchExternal]="true"
            (eventKeyUp)="onKeyPress()"
            placeholder="Pesquisar por assunto"
          >
          </app-select-search>
        </div>
        <div
          class="marcador"
          [ngClass]="{ 'd-none': isVisaoMobile && openInputSearchAssunto }"
          [ngStyle]="{ 'margin-left': isVisaoMobile ? '7px' : '16px' }"
        >
          <app-list-tag
            (eventTag)="filterTag($event)"
            (eventAtualizacaoTag)="this.getPapers('', '')"
          ></app-list-tag>
        </div>
      </div>
    </div>
  </header>
  <div class="app-kanban-documentos d-flex">
    <div class="kanban-documents-desktop-tablet">
      <div *ngFor="let field of kanbanField">
        <app-kanban-documents-field
          [chamador]="'area-logged'"
          [situacaoPaper]="'enviados'"
          [title]="field.title"
          [color]="field.color"
          [colorDark]="field.colorDark"
          [isShippable]="field.isShippable"
          [papers]="field.papers"
          [imageEmpty]="field.imageEmpty"
          [messageEmpty]="field.messageEmpty"
          (eventCard)="eventCardEmitter($event)"
          (eventKanban)="eventKanbanEmitter($event)"
          class="flex-grow-1"
        ></app-kanban-documents-field>
      </div>
    </div>
    <div class="mobile flex-grow-1">
      <div class="d-flex mx-2 mb-1 justify-content-around">
        <div
          class="selector d-flex align-items-center pl-1"
          *ngFor="let field of kanbanField"
          (click)="changeField(field)"
          [ngClass]="isFieldSelected(field) ? 'selected' : 'wBody10'"
        >
          <div class="mb-1">
            {{ field.titleMobile }}
          </div>
          <div
            class="number-mobile mb-1 ml-2 d-flex justify-content-center border rounded"
          >
            {{ field.papers.length }}
          </div>
        </div>
      </div>
      <app-kanban-documents-field
        *ngIf="kanbanSelected !== undefined"
        [chamador]="'area-logged'"
        [situacaoPaper]="'enviados'"
        [title]="kanbanSelected.title"
        [color]="kanbanSelected.color"
        [colorDark]="kanbanSelected.colorDark"
        [isShippable]="kanbanSelected.isShippable"
        [papers]="kanbanSelected.papers"
        [imageEmpty]="kanbanSelected.imageEmpty"
        [messageEmpty]="kanbanSelected.messageEmpty"
        (eventCard)="eventCardEmitter($event)"
        (eventKanban)="eventKanbanEmitter($event)"
      ></app-kanban-documents-field>
    </div>
  </div>
</div>
<app-modal-confirm-descart-paper
  (update)="updateList()"
></app-modal-confirm-descart-paper>
