<div class="field border flex-grow-1">
    <div class="field-header p-3 title-desktop" [ngStyle]="{'background-color': color}">
        <div class="d-flex align-items-center mx-2 pt-1">
            <div *ngIf="situacaoPaper !== 'descartados'">
                <app-checkbox [form]="formCheck" (checkedEvent)="changeChecked($event)"></app-checkbox>
            </div>
            <div class="Heading16 mb-1 text-position">{{title}}</div>
        </div>
        <div>
            <div *ngIf="situacaoPaper !== 'descartados'" class="mx-2 number body12 px-2 py-1"
                [ngStyle]="{'background-color': colorDark}">
                {{papersSelected.length}}
            </div>
            <div *ngIf="situacaoPaper !== 'enviados'" class="mx-2 number body12 px-2 py-1"
                [ngStyle]="{'background-color': colorDark}">
                {{papers.length}}
            </div>
        </div>
    </div>


    <div class="field-body">

        <button *ngIf="!isPapersEmpty() && chamador === 'area-not-logged' && index !== 2"
            [disabled]="isPapersSelectEmpty()" class="btn assinar-documento" (click)="send()"><i
                class="np-edit iconBody18 mr-1"></i>Assinar documentos</button>

        <div class="button-group p-3 d-flex" *ngIf="!isPapersEmpty() && chamador === 'area-logged'">
            <div class="checkbox-mobile">
                <app-checkbox [form]="formCheck" (checkedEvent)="changeChecked($event)"></app-checkbox>
            </div>
            <div *ngIf="situacaoPaper !== 'descartados'" class="d-flex" ngbDropdown #dropdownMarcador="ngbDropdown" container="body">
                <button class="btn btn-icon border mx-1 color-400" [disabled]="isPapersSelectEmpty()" (click)="send()"
                    *ngIf="isShippable" ngbTooltip="Enviar" container="body">
                    <i class="np-sending iconBody18"></i>
                </button>
                <button class="btn btn-icon border mx-1 color-400" [disabled]="isPapersSelectEmpty()" (click)="trash()"
                    ngbTooltip="Descartar" container="body">
                    <i class="np-trash iconBody18"></i>
                </button>
                <button *ngIf="this.OCULTAR_BOTAO_MARCADORES" class="btn btn-icon border mx-1 color-400" [disabled]="isPapersSelectEmpty()" ngbDropdownToggle
                    ngbTooltip="Marcador" (click)="this.carregarTags()">
                    <i class="np-tag iconBody18"></i>
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownConfig" class="container-tag-dropdown-menu px-3 py-1"
                    [ngClass]="{'totalmente': title === 'Totalmente assinados', 'parcial': title !== 'Totalmente assinados'}">
                    <div class="header d-flex justify-content-between align-items-center">
                        <span class="Heading16">Selecionar marcador</span>
                        <i class="np np-close iconBody22 color-gray-300" (click)="dropdownMarcador.close()"></i>
                    </div>

                    <div class="scroll-tela">
                        <div *ngFor="let currentTag of currentTags; let i=index"
                            class="border w-100 body14 pl-3 pt-2 pb-2 rounded mt-1">
                            <div class="d-flex justify-content-between">
                                <div>
                                    <i class="np np-tag iconBody14" [ngStyle]="{ color: currentTag.color }"></i>
                                    <span class="wBody14"> {{ currentTag.name }}</span>
                                </div>
                                <div class="input-checkbox">
                                    <app-checkbox [form]="formCheck" [checked]="currentTag.isChecked"
                                        (checkedEvent)="isCheckedItem(!currentTag.isChecked, i)"></app-checkbox>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="d-flex justify-content-center">
                        <button class="body14 btn btn-primary salvar-tags mx-5 my-3 w-100" [disabled]="tagsSelected.length === 0"
                            (click)="salvarTags(); dropdownMarcador.close();">
                            <i class="np-plus iconBody18 mr-2 color-gray-000"></i>
                            <span class="color-gray-000"> Salvar </span>
                        </button>
                    </div>
                </div>
            </div>
            <!-- <button class="btn btn-icon border mx-1 color-400" [disabled]="isPapersSelectEmpty()" (click)="btnClick()" ngbTooltip="Mover">
            <i class="np-move-folder iconBody18"></i>
        </button> -->
        </div>
        <div class="px-md-3 pb-3 d-flex">
            <div class="flex-grow-1" *ngIf="!isPapersEmpty()">
                <div class="mb-md-2" *ngFor="let paper of papers">
                    <app-card-documents [id]="paper.id" [chamador]="chamador" [situacaoPaper]="situacaoPaper"
                        [typeSignature]="paper.typeSignature" [date]="paper.date" [signed]="paper.signed"
                        [totalSign]="paper.totalSign" [subject]="paper.subject" [fullySigned]="paper.fullySigned"
                        [tags]="paper.tags" [event]="eventPaper" (checkboxEvent)="changeCheckedChildren($event)"
                        (eventCard)="eventsCard($event)" [titulo]="title"></app-card-documents>
                </div>
            </div>
            <div *ngIf="isPapersEmpty()" class="d-flex flex-grow-1 justify-content-center flex-column mt-5 pt-5">
                <div class="d-flex justify-content-center">
                    <img src="assets/images/{{imageEmpty}}.svg" alt="Nenhum documento" height="150">
                </div>
                <div class="d-flex justify-content-center wBody14 pt-2">
                    {{messageEmpty}}
                </div>
            </div>
        </div>
    </div>

</div>
