<div class="modal-termos-de-uso">
  <div class="body">
    <div class="d-flex justify-content-between align-items-center">
      <div class="d-flex align-items-center mb-2">
        <app-avatar icon="np-Icon-file"
                    size="small"
                    color="primary"></app-avatar>
        <span class="Heading20 mx-4">Termos e condições de uso</span>
      </div>
      <i class="np-close iconBody22 color-400"
         (click)="closeModalTermos()"></i>
    </div>
    <div class="termos">
      <p>
        <li><strong>APRESENTA&Ccedil;&Atilde;O E DEFINI&Ccedil;&Otilde;ES</strong></li>
    </p>
    <p><span>O dom&iacute;nio eletr&ocirc;nico </span><span><a
                href="https://www.npaper.com.br">https://www.npaper.com.br</a></span><span>&nbsp;hospeda
            p&aacute;ginas de divulga&ccedil;&atilde;o e publicidade, al&eacute;m de
            fornecer
            acesso ao software para assinaturas eletr&ocirc;nicas, NPAPER. Este dom&iacute;nio &eacute; mantido
            e
            operado pela DECIS&Atilde;O INFORM&Aacute;TICA LTDA-EPP (Decis&atilde;o Sistemas), sua &uacute;nica
            propriet&aacute;ria, com sede administrativa na Rua 137, N&ordm; 556, Quadra 50, Lote 01, Sala 101,
            1&ordm;
            Andar, Edif&iacute;cio Alvorada, Setor Marista, Goi&acirc;nia, Goi&aacute;s, CEP: 74170-120, Brasil,
            inscrita no CNPJ/MF sob o n&ordm; 25.076.589/0001-32.</span></p>
    <p><span></span></p>
    <p><span>Para os fins destes Termos e Condi&ccedil;&otilde;es de Uso, as seguintes
            palavras
            ter&atilde;o os significados atribu&iacute;dos abaixo:</span></p>
    <ul>
        <li><span>Plataforma:</span><span>&nbsp;Refere-se ao website
                desenvolvido,
                mantido e operado pela Decis&atilde;o Sistemas, incluindo todas as suas p&aacute;ginas,
                hospedado no
                dom&iacute;nio </span><span><a
                    href="https://www.npaper.com.br">www.npaper.com.br</a></span><span>;</span>
        </li>
        <li><span>Contratantes:</span><span>&nbsp;Indica pessoas
                f&iacute;sicas ou jur&iacute;dicas que tenham firmado Contrato de Licen&ccedil;a de Uso de
                Software com
                a Decis&atilde;o Sistemas e enviam mensagens com links de acesso ao painel de assinaturas para
                seus
                clientes e/ou parceiros;</span></li>
        <li><span>Usu&aacute;rio:</span><span>&nbsp;Engloba todas
                as
                pessoas que acessam a Plataforma, incluindo Contratantes, partes envolvidas nos documentos a
                serem
                assinados ou visitantes an&ocirc;nimos;</span></li>
        <li><span>Conta:</span><span>&nbsp;Refere-se aos dados de
                identifica&ccedil;&atilde;o do Usu&aacute;rio (Nome, CPF ou CNPJ e e-mail) utilizados para
                acessar a
                Plataforma;</span></li>
        <li><span>Assinatura eletr&ocirc;nica:</span><span>&nbsp;&Eacute;
                um conjunto de dados digitais que serve para identificar e autenticar o(s) signat&aacute;rio(s)
                e
                confirmar a concord&acirc;ncia dele(s) com o conte&uacute;do de um documento ou
                transa&ccedil;&atilde;o
                online, garantindo a sua autenticidade, integridade e n&atilde;o rep&uacute;dio. Pode ser feita
                por meio
                de um certificado digital, uma senha, um sinal ou at&eacute; mesmo um simples toque na tela do
                celular:</span></li>
        <li><span>Assinatura digital:</span><span>&nbsp;&Eacute; um
                conjunto de dados digitais que serve para identificar e autenticar o(s) signat&aacute;rio(s) e
                confirmar
                a concord&acirc;ncia dele(s) com o conte&uacute;do de um documento ou transa&ccedil;&atilde;o
                online,
                garantindo a sua autenticidade, integridade e n&atilde;o rep&uacute;dio. Ela &eacute; baseada em
                um
                certificado digital, que &eacute; um arquivo eletr&ocirc;nico emitido por uma Autoridade
                Certificadora
                (AC);</span></li>
        <li><span>Certificado digital:</span><span>&nbsp;&Eacute;
                um
                documento eletr&ocirc;nico que cont&eacute;m dados sobre uma pessoa ou empresa, servindo como
                uma
                identidade virtual que confere validade jur&iacute;dica e aspectos de seguran&ccedil;a digital
                em
                transa&ccedil;&otilde;es digitais. Ele &eacute; emitido por uma Autoridade Certificadora (AC)
                credenciada pela ICP-Brasil;</span></li>
        <li><span>Carimbo do tempo:</span><span>&nbsp;Consiste em
                um selo
                que atesta a data e hora oficiais do Brasil, emitido por uma Autoridade de Carimbo do Tempo
                credenciada
                pelo Instituto da Tecnologia da Informa&ccedil;&atilde;o;</span></li>
        <li><span>ICP-Brasil:</span><span>&nbsp;&Eacute; a
                Infraestrutura
                de Chaves P&uacute;blicas Brasileira, autoridade gestora das pol&iacute;ticas para
                emiss&atilde;o e
                controle de certificados digitais.</span></li>
    </ul>
    <p><span></span></p>
    <p><span>Este documento estabelece os termos e condi&ccedil;&otilde;es que devem ser observados
            pelos
            Usu&aacute;rios na utiliza&ccedil;&atilde;o da Plataforma, suas ferramentas e funcionalidades.
        </span><span>Ao acessar a Plataforma ou utilizar suas fun&ccedil;&otilde;es, o Usu&aacute;rio concorda
            com estes
            Termos e Condi&ccedil;&otilde;es de Uso, sendo imprescind&iacute;vel a leitura atenta antes de
            prosseguir
            com o acesso ou uso da Plataforma.</span></p>
    <p><span></span></p>
    <p><span>Al&eacute;m disso, armazena e disponibiliza esses documentos para consulta e
            impress&atilde;o. Importante ressaltar que o NPAPER n&atilde;o atua como prestador de
            servi&ccedil;os de
            consultoria ou intermedia&ccedil;&atilde;o nos neg&oacute;cios entre os Usu&aacute;rios e/ou
            Signat&aacute;rios. Portanto, a Plataforma n&atilde;o possui responsabilidade na
            rela&ccedil;&atilde;o entre
            Contratantes e Usu&aacute;rios.</span></p>
    <p><span></span></p>
    <p><span>Para utilizar as funcionalidades do NPAPER, o Usu&aacute;rio deve ter pleno conhecimento
            destes
            Termos e Condi&ccedil;&otilde;es de Uso e da </span><span><a
                href="https://decisaosistemas.com.br/politica-de-privacidade/">Pol&iacute;tica
                de Privacidade</a></span><span>, que cont&eacute;m informa&ccedil;&otilde;es
            detalhadas sobre
            coleta, uso, armazenamento, tratamento e prote&ccedil;&atilde;o dos dados pessoais do
            Usu&aacute;rio.</span>
    </p>
    <h3><span>REGRAS DE UTILIZA&Ccedil;&Atilde;O DA
            PLATAFORMA</span></h3>
    <p><span>O Usu&aacute;rio compromete-se a utilizar a Plataforma em conformidade com estes Termos
            e
            Condi&ccedil;&otilde;es de Uso, a</span><span><a
                href="https://decisaosistemas.com.br/politica-de-privacidade/">&nbsp;Pol&iacute;tica
                de Privacidade</a></span><span>, a legisla&ccedil;&atilde;o vigente, os costumes e a
            ordem
            p&uacute;blica. Atos que violem essas regras, como prop&oacute;sitos ilegais,
            corrup&ccedil;&atilde;o de
            informa&ccedil;&otilde;es, viola&ccedil;&atilde;o de direitos de propriedade industrial ou autoral,
            e envio
            de mensagens ofensivas, podem resultar no encerramento do acesso &agrave; Plataforma.</span></p>
    <p><span></span></p>
    <p><span>O NPAPER reserva-se o direito de bloquear, restringir, desabilitar ou impedir o acesso
            de
            qualquer Usu&aacute;rio &agrave; Plataforma, total ou parcialmente, caso seja detectada uma conduta
            contr&aacute;ria aos Termos e Condi&ccedil;&otilde;es de Uso ou &agrave; </span><span><a
                href="https://decisaosistemas.com.br/politica-de-privacidade/">Pol&iacute;tica
                de Privacidade</a></span><span>. O NPAPER e seus Contratantes est&atilde;o isentos de
            responsabilidade por danos causados por m&aacute; utiliza&ccedil;&atilde;o da Plataforma ou
            viola&ccedil;&atilde;o destes Termos e Condi&ccedil;&otilde;es de Uso.</span></p>
    <h3><span>CADASTRO DE USU&Aacute;RIOS</span></h3>
    <p><span>Para acessar todas as funcionalidades da Plataforma, os Usu&aacute;rios da
            Contratante devem criar uma Conta, fornecendo dados corretos e completos, incluindo
            informa&ccedil;&otilde;es de pagamento. </span></p>
    <p><span></span></p>
    <p><span>Outros Usu&aacute;rios acessam a Plataforma por links enviados pelos Contratantes para
            assinaturas. </span><span>A cria&ccedil;&atilde;o de Contas, inser&ccedil;&atilde;o de assinaturas
            eletr&ocirc;nicas e a apresenta&ccedil;&atilde;o de certificados digitais implica no consentimento
            para
            coleta, uso, armazenamento e tratamento de dados pessoais pelo NPAPER.</span></p>
    <p><span></span></p>
    <p><span>Cada usu&aacute;rio Contratante pode criar apenas uma Conta de acesso,
            identificada
            pelo CPF ou CNPJ. O NPAPER reserva-se o direito de suspender ou cancelar Contas duplicadas. O
            Usu&aacute;rio
            &eacute; respons&aacute;vel por manter seu nome de usu&aacute;rio e senha em sigilo, sendo sua
            responsabilidade comunicar qualquer perda, divulga&ccedil;&atilde;o, roubo ou uso n&atilde;o
            autorizado da
            Conta.</span></p>
    <p><span></span></p>
    <p><span>Menores de 18 anos s&oacute; podem usar a Plataforma com a
            representa&ccedil;&atilde;o ou assist&ecirc;ncia de pais ou respons&aacute;veis legais. A
            cria&ccedil;&atilde;o de Contas em nome de menores requer representa&ccedil;&atilde;o por um adulto,
            que
            &eacute; respons&aacute;vel civil e criminalmente por atos do menor na Plataforma.</span></p>
    <h3><span class="c1 c10">DADOS PESSOAIS, PRIVACIDADE E
            SEGURAN&Ccedil;A</span></h3>
    <p><span>O NPAPER segue uma </span><span><a
                href="https://decisaosistemas.com.br/politica-de-privacidade">Pol&iacute;tica
                de Privacidade</a></span><span>&nbsp;que regula a coleta, guarda e utiliza&ccedil;&atilde;o de
            dados
            pessoais, garantindo a seguran&ccedil;a desses dados. Esta </span><span><a
                href="https://decisaosistemas.com.br/politica-de-privacidade">Pol&iacute;tica
                de Privacidade</a></span><span>&nbsp;&eacute; parte integrante destes Termos e
            Condi&ccedil;&otilde;es de Uso, e os dados de utiliza&ccedil;&atilde;o da Plataforma s&atilde;o
            arquivados
            conforme a legisla&ccedil;&atilde;o aplic&aacute;vel.</span></p>
    <h3><span>OFERTA DE FUNCIONALIDADES</span></h3>
    <p><span>O NPAPER oferece funcionalidades para registro de assinaturas digitais e/ou
            eletr&ocirc;nicas, e
            opcionalmente, Carimbos do Tempo com a hora oficial do Brasil, em documentos assinados por
            Contratantes e
            seus clientes e/ou parceiros. As assinaturas digitais seguem os padr&otilde;es definidos pela
            Infraestrutura
            de Chaves P&uacute;blicas Brasileira (ICP-Brasil), garantindo autenticidade, integridade e validade
            jur&iacute;dica dos documentos eletr&ocirc;nicos e assinaturas.</span></p>
    <p><span></span></p>
    <p><span>Documentos eletr&ocirc;nicos assinados digitalmente ou eletronicamente s&atilde;o
            armazenados e
            dispon&iacute;veis para acesso por 5 anos. Ap&oacute;s esse per&iacute;odo, os documentos s&atilde;o
            definitivamente eliminados.</span></p>
    <h3><span>PRE&Ccedil;OS E PAGAMENTOS</span></h3>
    <p><span>A responsabilidade pelo pagamento do uso da Plataforma recai exclusivamente
            sobre os
            Usu&aacute;rios Contratantes, conforme estipulado no Contrato de Licen&ccedil;a de Uso de Software
            firmado
            com a Decis&atilde;o Sistemas. Outros Usu&aacute;rios (signat&aacute;rios) podem acessar documentos
            eletr&ocirc;nicos enviados a eles para assinatura, sem custos adicionais. Para contratar outras
            funcionalidades, devem entrar em contato com a Decis&atilde;o Sistemas para obter
            informa&ccedil;&otilde;es
            sobre a contrata&ccedil;&atilde;o.</span></p>
    <h3><span>PROPRIEDADE INTELECTUAL, DIREITOS AUTORAIS E
            MARCAS</span>
    </h3>
    <p><span>Todo o conte&uacute;do do NPAPER, incluindo apresenta&ccedil;&atilde;o,
            layout,
            marcas, logotipos, produtos, sistemas, funcionalidades, programas, bases de dados e imagens,
            &eacute;
            protegido por leis de Propriedade Intelectual e &eacute; de propriedade da Decis&atilde;o Sistemas.
            A
            reprodu&ccedil;&atilde;o, distribui&ccedil;&atilde;o e transmiss&atilde;o desse material sem
            consentimento
            expresso s&atilde;o proibidas e caracteriza infra&ccedil;&atilde;o legal, sujeitando o infrator a
            a&ccedil;&otilde;es judiciais para indeniza&ccedil;&atilde;o de danos causados.</span></p>
    <h3 id="h.8pvvanq3lqet"><span>COMUNICA&Ccedil;&Atilde;O</span></h3>
    <p><span>O NPAPER oferece uma Central de Atendimento operada por meio dos seguintes
            canais:</span></p>
    <ul class="c12 lst-kix_8kurs1wpnm9m-0 start">
        <li class="c8 c9 li-bullet-0"><span>Chat dispon&iacute;vel na Plataforma 24h/dia, 7
                dias/semana, com
                atendimento de segunda a sexta-feira, exceto feriados, das 08:00 &agrave;s 18:00hr;</span></li>
        <li class="c8 c9 li-bullet-0"><span>Whatsapp (062) 4013-9300, de segunda a sexta-feira,
                exceto
                feriados, das 08:00 &agrave;s 18:00hr;</span></li>
        <li class="c8 c9 li-bullet-0"><span>E-mail: npaper&#64;decisaosistemas.com.br.</span></li>
    </ul>
    <h3 id="h.5h8v9flpv9r0"><span>DURA&Ccedil;&Atilde;O E
            ATUALIZA&Ccedil;&Atilde;O</span>
    </h3>
    <p><span>Estes Termos</span><span>&nbsp;e Condi&ccedil;&otilde;es de Uso entram em
            vigor
            quando o Usu&aacute;rio os aceita e permanecer&atilde;o em vigor enquanto a Plataforma estiver
            ativa. Eles
            podem ser revisados, aprimorados, modificados e atualizados unilateralmente a qualquer momento. A
            continuidade de acesso ou utiliza&ccedil;&atilde;o da Plataforma ap&oacute;s qualquer
            modifica&ccedil;&atilde;o implica na aceita&ccedil;&atilde;o dos novos termos.</span></p>
    <p><span></span></p>
    <p><span>Se algum </span><span>Usu&aacute;rio</span><span>&nbsp;n&atilde;o concordar com as
            modifica&ccedil;&otilde;es realizadas, pode cancelar sua conta, o que n&atilde;o eximir&aacute; sua
            responsabilidade quanto &agrave; aceita&ccedil;&atilde;o da vers&atilde;o anterior dos Termos e
            Condi&ccedil;&otilde;es de Uso.</span></p>
    <h3 id="h.olwl90hvzjs0"><span>DISPOSI&Ccedil;&Otilde;ES GERAIS</span></h3>
    <p><span>Para assinaturas digitais, ser&aacute; considerado o hor&aacute;rio oficial
            de
            Bras&iacute;lia, incluindo per&iacute;odos de hor&aacute;rio de ver&atilde;o. Nem a Plataforma, nem
            os
            Usu&aacute;rios Contratantes ser&atilde;o responsabilizados por descumprimento das
            obriga&ccedil;&otilde;es
            nestes Termos e Condi&ccedil;&otilde;es de Uso em caso de caso fortuito ou for&ccedil;a
            maior.</span></p>
    <p><span></span></p>
    <p><span>O NPAPER atua virtualmente respeitando seus Usu&aacute;rios, em conformidade
            com o
            C&oacute;digo de Defesa do Consumidor (Lei n&ordm; 8.078, de 11 de setembro de 1990), o Marco Civil
            da
            Internet (Lei n&ordm; 12.965, de 23 de abril de 2014), a LGPD (Lei n&ordm; 13.709, de 14 de agosto
            de 2018)
            &nbsp;e outras normas pertinentes.</span></p>
    <h3><span>FORO DE ELEI&Ccedil;&Atilde;O</span></h3>
    <p><span>A Plataforma &eacute; controlada e operada pela
        </span><span>DECIS&Atilde;O</span><span>&nbsp;</span><span>INFORM&Aacute;TICA</span><span>&nbsp;LTDA-EPP,
            com
            sede em Goi&acirc;nia, Goi&aacute;s, e pode ser acessada de qualquer dispositivo conectado &agrave;
            internet, independentemente da localiza&ccedil;&atilde;o geogr&aacute;fica. Ao acessar a Plataforma,
            o
            Usu&aacute;rio concorda que a legisla&ccedil;&atilde;o aplic&aacute;vel para reger estes Termos e
            Condi&ccedil;&otilde;es de Uso, assim como a </span><span><a
                href="https://decisaosistemas.com.br/politica-de-privacidade/">Pol&iacute;tica
                de Privacidade</a></span><span>, &eacute; aquela vigente no territ&oacute;rio da
            Rep&uacute;blica Federativa do Brasil. O Usu&aacute;rio concorda em eleger o foro da Comarca de
            Goi&acirc;nia, Goi&aacute;s, como &uacute;nico competente para resolver quest&otilde;es oriundas do
            uso da
            Plataforma, renunciando a qualquer outro foro, por mais privilegiado que seja.</span></p>
    <p><span></span></p>
    <p><span>Goi&acirc;nia, Goi&aacute;s, Brasil, 14 de outubro de 2023.</span></p>
    </div>
    <div class="d-flex justify-content-end"
         (click)="closeModalTermos()">
      <button class="btn btn-icon color-400 my-2">
        <i class="np-error m-1"></i>
        <span class="body14">
          Fechar
        </span>
      </button>
    </div>
  </div>
</div>
