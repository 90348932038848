<div class="sidebar">
  <div style="margin-top: 309px" class="button-profile">
    <button [routerLink]="['enviados']" routerLinkActive="router-button-active " type="button"
      class="button-profile-items">
      <i class="np-outgoing-mail iconBody26"></i><span class="Heading16">Enviados</span>
    </button>
    <button type="button" class="button-profile-items" [routerLink]="['descartados']"
      routerLinkActive="router-button-active ">
      <i class="np-trash iconBody26"></i><span class="Heading16">Descartados</span>
    </button>

    <!-- CONTEÚDO NÃO IMPLEMENTADO POR ENQUANTO -->

    <!-- <button type="button" class="button-profile-items" [routerLink]="['pastas']"
      routerLinkActive="router-button-active ">
      <i class="np-folder iconBody26"></i><span class="Heading16">Pastas</span>
    </button> -->
    <button type="button" class="button-profile-items" [routerLink]="['assinar-em-lote']"
      routerLinkActive="router-button-active ">
      <i class="np-edit iconBody26"></i><span class="Heading16">Assinar em lote</span>
    </button>
  </div>
</div>
