import { Component, Input, OnInit, Sanitizer, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AssinaturaDesenhoComponent } from 'src/app/shared/components/assinatura-desenho/assinatura-desenho.component';
import { AssinaturaDigitarComponent } from 'src/app/shared/components/assinatura-digitar/assinatura-digitar.component';
import { ModalTermosDeUsoService } from 'src/app/shared/components/modal-termos-de-uso/modal-termos-de-uso.service';
import { ModelosAssinaturasEletronicasEnum } from 'src/app/shared/enum/modelos-assinaturas-eletronica.enum';
import { EletronicSignData } from 'src/app/shared/models/eletronicSignData';

@Component({
  selector: 'app-modal-assinatura',
  templateUrl: './modal-assinatura.component.html',
  styleUrls: ['./modal-assinatura.component.scss']
})
export class ModalAssinaturaComponent implements OnInit {
  @ViewChild('assinarDesenho') assinarDesenhoComponent!: AssinaturaDesenhoComponent;
  @ViewChild('assinarDigitar') assinarDigitarComponent!: AssinaturaDigitarComponent;

  @Input() assinaturaCarregada?: string;
  public tipoDesenhoAssinatura: ModelosAssinaturasEletronicasEnum;
  public modeloAssinaturas = ModelosAssinaturasEletronicasEnum;
  public assinaturaCarregadaTratada: SafeResourceUrl | undefined;

  constructor(
    private activeModal: NgbActiveModal,
    private sanatizer: DomSanitizer,
    private modalTermosDeUsoService: ModalTermosDeUsoService,
  ) {
  }

  ngOnInit(): void {
    if (!!this.assinaturaCarregada !== false) {
      this.tipoDesenhoAssinatura = ModelosAssinaturasEletronicasEnum.carregada;
      this.assinaturaCarregadaTratada = this.sanatizer.bypassSecurityTrustResourceUrl('data:image/png;base64,' + this.assinaturaCarregada);
    } else {
      this.tipoDesenhoAssinatura = ModelosAssinaturasEletronicasEnum.desenho;
    }
  }

  public showTermos(): void {
    this.modalTermosDeUsoService.showTermos();
  }

  cancelamento(): void {
    this.activeModal.close(null);
  }

  confirmacao(): void {
    let dados: string = '';
    switch (this.tipoDesenhoAssinatura) {
      case ModelosAssinaturasEletronicasEnum.carregada:
        dados = this.assinaturaCarregada ?? '';
        break;
      case ModelosAssinaturasEletronicasEnum.desenho:
        dados = this.assinarDesenhoComponent.getAssinaturaDados();
        break;
      case ModelosAssinaturasEletronicasEnum.escrita:
        dados = this.assinarDigitarComponent.getAssinaturaDados();
        break;
    }
    this.activeModal.close(new EletronicSignData(dados, this.tipoDesenhoAssinatura));
  }
}
