<div class="app-modal-delete-responsibility p-3 px-md-4 py-3">
    <div class="row">
        <div class="col-md-12 d-flex justify-content-end">
            <a (click)="closeModal(false)">
                <i class="iconBody22 np np-close-doc np np-close-doc color-gray-300"></i>
            </a>
        </div>
        <div class="col-md-12 d-flex justify-content-center pb-2">
            <app-avatar color="danger" icon="np-trash"></app-avatar>
        </div>
        <div class="col-md-12 d-flex justify-content-center my-2">
            <span class="Heading20 text-center">
                <strong class="text-nowrap">Deseja excluir responsabilidade?</strong>
                <p class="wBody14">Após confirmação essa ação não poderá ser revertida. </p>
            </span>
        </div>
        <div class="col-md-12 d-flex justify-content-center mb-3">
            <button class="wbody14 btn-close btn btn-secondary mr-2" (click)="closeModal(false)">
                <i class="iconBody18 mr-2 np np-error"></i> Cancelar
            </button>
            <button class="wbody14 btn btn-danger" (click)="this.deleteResponsibility()">
                <i class="iconBody18 mr-2 np np-trash"></i> Sim, excluir
            </button>
        </div>
    </div>
</div>
