export class DateUtil {

  static format(date: Date | string, format: string): string {
    if (typeof date === 'string') {
      date = new Date(date);
      date.setHours(date.getHours() + 3);
    }

    const formattedDate = format
      .replace(/DD/g, date.getUTCDate().toString().padStart(2, '0'))
      .replace(/D/g, date.getUTCDate().toString())
      .replace(/MM/g, (date.getUTCMonth() + 1).toString().padStart(2, '0'))
      .replace(/M/g, (date.getUTCMonth() + 1).toString())
      .replace(/YYYY/g, date.getUTCFullYear().toString())
      .replace(/hh/g, date.getUTCHours().toString())
      .replace(/mm/g, date.getUTCMinutes().toString())
      .replace(/ss/g, date.getUTCSeconds().toString());

    return formattedDate;
  }

  static iniciarData(pData: string | Date): Date {
    try {
      let data: Date;
      if (typeof pData === 'string') {
        data = new Date(`${pData.split('T')[0]} 00:00:00`);
        data = new Date(data.getTime() + new Date().getTimezoneOffset() / 60);
      } else {
        data = pData;
        data.setMilliseconds(0);
      }
      return data;
    } catch (error) {
      throw error;
    }
  }


  static formatDate(date: string): string {
    const dt = new Date(date);

    // Garante que a data seja formatada no fuso horário correto
    const formattedDate = dt.toLocaleDateString('pt-BR', { timeZone: 'America/Sao_Paulo' });
    const formattedTime = dt.toLocaleTimeString('pt-BR', { timeZone: 'America/Sao_Paulo', hour: '2-digit', minute: '2-digit' });

    const months: string[] = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];

    // Extraindo dia, mês e ano corretamente
    const [day, month, year] = formattedDate.split('/');
    const monthName = months[parseInt(month, 10) - 1];

    return `${day} ${monthName} ${year} - ${formattedTime}`;
  }

}
