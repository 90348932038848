<div class="app-form-recover-password">
    <span class="redefine-password"><b>Redefinir senha!</b></span>
    <span class="redefine-link mt-3">Um link para redefinição de senha será enviado para o e-mail informado
        abaixo!
    </span>
    <form>
        <div class="inner-addon right-addon">
            <app-input [inputControl]="emailControl" [label]="'E-mail'" [isRequired]="true" [placeholder]="'Digite seu e-mail'">
            </app-input>
        </div>
        <div class="row mt-4">
            <div class="col-12">
                <button class="btn btn-send-recover w-100" [disabled]="formulario.valid ===false" (click)="onSubmit()">
                    Enviar para redefinição
                    <i class="np-angle-right iconBody18 ml-2"></i>
                </button>
            </div>
        </div>
    </form>
    <div class="col-12 mt-3">
        <p class="register">Não possui cadastro no Npaper? <a class="mx-1" [routerLink]="['../register']">Criar uma
                conta.</a>
        </p>
    </div>
    <div class="col-12 px-0 mt-2 termos color-cinza">
      O tratamento dos dados pessoais está autorizado conforme descrito no contrato ou no
      <a class="pointer color-brand-400" (click)="showTermos()" >Termos de uso</a> e na
      <a class="pointer color-brand-400" href="https://decisaosistemas.com.br/politica-de-privacidade/" target="_blank">Política de privacidade</a>
    </div>
    <div class="row">
        <div class="col-12">
            <a [routerLink]="['../']">
                <button class="btn btn-return w-100">
                    Retornar para login
                </button>
            </a>
        </div>
    </div>
    <div class="col-12 mt-2 meet-npaper">
        <a href="https://npaper.com.br"><b>Clique e conheça o Npaper</b></a>
    </div>
</div>
