import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalTermosDeUsoComponent } from './modal-termos-de-uso.component';

@Injectable({
  providedIn: 'root'
})
export class ModalTermosDeUsoService {
  private modalRef?: NgbModalRef;

  constructor(private ngbModal: NgbModal) { }

  public showTermos(): void {
    this.modalRef = this.ngbModal.open(ModalTermosDeUsoComponent, { size: 'lg' });
  }
}
